import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// auth & utils
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import { UseOpenCheckoutPortal } from 'src/api/product';

// ----------------------------------------------------------------------

export default function PricingCard({ card, sx, ...other }) {
  const { subscription, price, caption, lists } = card;
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const isCurrentPlan = subscription === user.subscription.name;
  const isBasicPlan = subscription === 'basic';
  const isEnterprisePlan = subscription === 'Enterprise';

  const handleStripeCheckoutRedirect = async (sub) => {
    if (isEnterprisePlan) {
      window.open("https://www.teledomica.com/shopifycontactus");
    } else {
      setLoading(true);
      const portalUrl = await UseOpenCheckoutPortal(sub);
      setLoading(false);
      window.open(portalUrl.redUrl.url.url, "_self");
    }
  };

  const getPlanLabel = () => {
    if (isCurrentPlan) return 'Current Plan';
    if (isBasicPlan) return 'Basic Plan';
    if (isEnterprisePlan) return 'CONTACT';
    return 'Upgrade Plan';
  };

  const renderSubscription = (
    <Stack spacing={1}>
      <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
        {subscription}
      </Typography>
    </Stack>
  );

  const renderPrice = (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      {isBasicPlan ? (
        <Typography variant="h3">Free</Typography>
      ) : (
        <>
          {!isEnterprisePlan && <Typography variant="h3">$</Typography>}
          <Typography variant="h3">{price}</Typography>
          {!isEnterprisePlan && (
            <Typography variant="subtitle1" sx={{ opacity: 0.7 }}>/mo</Typography>
          )}
        </>
      )}
    </Stack>
  );
  

  const renderList = (
    <Stack spacing={2} minHeight="450px">
      <Box component="span" sx={{ typography: 'overline' }}>Features</Box>
      {lists.map((item) => (
        <Stack
          key={item}
          direction="row"
          alignItems="center"
          spacing={1}
          width="170px"
          sx={{ typography: 'body2' }}
        >
          <Iconify icon="eva:checkmark-fill" width={16} />
          {item}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Stack
      spacing={5}
      sx={{
        p: 5,
        borderRadius: 2,
        boxShadow: (theme) => ({
          xs: theme.customShadows.card,
          md: `-40px 40px 80px 0px ${alpha(
            theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
            0.16
          )}`,
        }),
        ...sx,
      }}
      {...other}
    >
      {renderSubscription}
      {renderPrice}
      {renderList}

      <Button
        fullWidth
        size="large"
        variant="contained"
        disabled={isBasicPlan || isCurrentPlan}
        color={subscription !== 'basic' ? 'primary' : 'inherit'}
        onClick={() => handleStripeCheckoutRedirect(subscription)}
      >
        {getPlanLabel()}
      </Button>

      {loading && <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360, mt: 5 }} />}
    </Stack>
  );
}

PricingCard.propTypes = {
  card: PropTypes.object.isRequired,
  sx: PropTypes.object,
};
