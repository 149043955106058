import { lazy, Suspense } from 'react';


// ----------------------------------------------------------------------



const Welcome = lazy(() => import('src/pages/welcome/list'));



export const welcomeRoutes = [
    {
        path: 'welcome',
        children: [
          { path: '', element: <Welcome /> },
        ],
      }
      
];
