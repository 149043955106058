// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// _mock
import { useAuthContext } from 'src/auth/hooks';
import { _pricingPlans } from 'src/_mock';
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect} from 'react';
import log from "loglevel";
// components
import { useSettingsContext } from 'src/components/settings';

import PricingCard from './pricing-card';


// ----------------------------------------------------------------------

export default function PricingView() {
  const { user } = useAuthContext();
  const settings = useSettingsContext();

  useEffect(() => {
    const analytics = getAnalytics();
       logEvent(analytics, "pricing_plan_viewed", {
         userId:user.uid,
       });
    
  }, [user]);


  function showPopupIframe(url) {
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    iframe.style.position = "fixed";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.zIndex = "1000";

    const existingIframe = document.getElementById('popupIframe');
    if (existingIframe) {
        document.body.removeChild(existingIframe);
    }

    iframe.id = 'popupIframe';

    document.body.appendChild(iframe);

    Array.from(document.body.children).forEach(child => {
        if (child.id !== 'popupIframe') {
            child.style.opacity = 0.5;
            child.style.pointerEvents = 'none';
        }
    });
}

  return (
    <Container maxWidth="sx">
      <Typography variant="h3" align="center" paragraph>
        Upgrade your plan to access more features!
      </Typography>

      {/* <Typography align="center" sx={{ color: 'text.secondary' }}>
        Choose your plan to make mixed-reality magic
      </Typography> */}
      <br />

        <Box
          gap={{ xs: 3, md: 0 }}
          display="grid"
          alignItems={{ md: 'center' }}
          gridTemplateColumns={{ md: 'repeat(5, 1fr)' }}
        >
          {_pricingPlans.map((card, index) => (
            <PricingCard key={card.subscription} card={card} index={index} />
          ))}
        </Box>
      

    </Container>
  );
}
