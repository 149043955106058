import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/feed/list'));

// PRODUCT
const SampleDetailsPage = lazy(() => import('src/pages/dashboard/sample/details'));
const SampleListPage = lazy(() => import('src/pages/dashboard/sample/list'));
const SampleCreatePage = lazy(() => import('src/pages/dashboard/sample/new'));
const SampleEditPage = lazy(() => import('src/pages/dashboard/sample/edit'));
const SharedWithMe = lazy(() => import('src/pages/dashboard/sharedWithMe/list'));
const SharedWithMeDetailsPage = lazy(() => import('src/pages/dashboard/sharedWithMe/details'));

// // SHOWCASE
// const ShowcaseDetailsPage = lazy(() => import('src/pages/dashboard/showcase/details'));
// const ShowcaseListPage = lazy(() => import('src/pages/dashboard/showcase/list'));
// const ShowcaseCreatePage = lazy(() => import('src/pages/dashboard/showcase/new'));
// const ShowcaseEditPage = lazy(() => import('src/pages/dashboard/showcase/edit'));

// // COLLECTION
// const CollectionDetailsPage = lazy(() => import('src/pages/dashboard/collection/details'));
// const CollectionListPage = lazy(() => import('src/pages/dashboard/collection/list'));
// const CollectionCreatePage = lazy(() => import('src/pages/dashboard/collection/new'));
// const CollectionEditPage = lazy(() => import('src/pages/dashboard/collection/edit'));

// USER
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserProfile = lazy(() => import('src/sections/user/view/user-profile-view'));
const ProductDetailsView= lazy(() => import('src/sections/user/view/product-details-view'));

// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
// FEED
const FeedDetailsPage = lazy(() => import('src/pages/dashboard/feed/details'));
const FeedListPage = lazy(() => import('src/pages/dashboard/feed/list'));
// LIBRARY
const LibraryDetailsPage = lazy(() => import('src/pages/dashboard/library/details'));
const LibraryListPage = lazy(() => import('src/pages/dashboard/library/list'));
// ----------------------------------------------------------------------

const PricingPage = lazy(() => import('src/pages/pricing'));


export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'user',
        children: [
          { path: 'account', element: <UserAccountPage /> },
          { path: 'userProfile', element: <UserProfile /> },
          { path: 'userProfile/:id', element: <UserProfile /> },
          { path: ':id', element: <ProductDetailsView />  },

        ],
      },
      {
        path: 'sampleroom',
        children: [
          { element: <SampleListPage />, index: true },
          { path: 'list', element: <SampleListPage /> },
          { path: ':id', element: <SampleDetailsPage /> },
          { path: 'new', element: <SampleCreatePage /> },
          { path: ':id/edit', element: <SampleEditPage /> },
          { path: 'sharedWithMe', element: <SharedWithMe /> },
          { path: 'sharedWithMe/:id', element: <SharedWithMeDetailsPage /> },
        ],
      },
      // {
      //   path: 'showcase',
      //   children: [
      //     { element: <ShowcaseListPage />, index: true },
      //     { path: 'list', element: <ShowcaseListPage /> },
      //     { path: ':id', element: <ShowcaseDetailsPage /> },
      //     { path: 'new', element: <ShowcaseCreatePage /> },
      //     { path: ':id/edit', element: <ShowcaseEditPage /> },
      //   ],
      // },
     
      {
        path: 'recents',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> }
        ],
      },
      {
        path: 'feed',
        children: [
          { element: <FeedListPage />, index: true },
          { path: 'list', element: <FeedListPage /> },
          { path: ':id', element: <FeedDetailsPage /> }
        ],
      },

      {
        path: 'products',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> }
        ],
      },

      {
        path: 'library',
        children: [
          { element: <LibraryListPage />, index: true },
          { path: 'list', element: <LibraryListPage /> },
          { path: ':id', element: <LibraryDetailsPage /> }
        ],
      },
      // {
      //   path: 'collection',
      //   children: [
      //     { element: <CollectionListPage />, index: true },
      //     { path: 'list', element: <CollectionListPage /> },
      //     { path: ':id', element: <CollectionDetailsPage /> },
      //     { path: 'new', element: <CollectionCreatePage /> },
      //     { path: ':id/edit', element: <CollectionEditPage /> },
      //   ],
      // },

      { path: 'pricing', element: <PricingPage /> }
      
    ],
  },
];
