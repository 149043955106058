import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { arRoutes } from './ar';
import { welcomeRoutes } from './welcome';
import { showroomRoutes } from './showroom';
import {brandRoutes} from './brand';
import {collectionRoutes} from './collection';
import {productRoutes} from './product';

// import { componentsRoutes } from './components';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
          <HomePage />
      ),
    },

    // Auth routes
    ...authRoutes,
    // Welcome routes
    ...welcomeRoutes,
    // Dashboard routes
    ...dashboardRoutes,
    ...arRoutes,
    ...brandRoutes,
    ...collectionRoutes,
    ...productRoutes,
    ...showroomRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
