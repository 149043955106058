import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState, useEffect } from "react";
import {
  Modal,
  Stack,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

export default function PairHeadset() {
  const [open, setOpen] = useState(false);
  const [pairingCode, setPairingCode] = useState(Array(6).fill(""));
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  const handleCodeChange = (e, index) => {
    const value = e.target.value.toUpperCase();
    if (/^[A-Z0-9]*$/.test(value)) {
      const newCode = [...pairingCode];
      newCode[index] = value;
      setPairingCode(newCode);

      // Move focus to the next box if the current one is filled
      if (value && index < 5) {
        const nextInput = document.getElementById(`input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  useEffect(() => {
    const isPairingCodeDisplayed = localStorage.getItem("isPairingCodeDisplayed");
    if (!isPairingCodeDisplayed) {
      setOpen(true);
    }
  }, []);


  const handleOpen = () => {
    setOpen(true);
    setError("");
    setSuccessMessage("");
    setPairingCode(Array(6).fill(""));
  };

  const handleClose = () => {
    localStorage.setItem("isPairingCodeDisplayed", "true");
    setOpen(false);
    setError("");
    setSuccessMessage("");
    setPairingCode(Array(6).fill(""));
  };


  const handlePair = async () => {
    const code = pairingCode.join("");
    if (code.length < 6) {
      setError("Please complete the pairing code.");
    } else {
      setError("");
      setSuccessMessage("");

      if (!code.trim()) {
        setError("Pairing code is required.");
        return;
      }

      setIsLoading(true); // Start loading indicator
      try {
        // Call Firebase Cloud Function
        const functions = getFunctions();
        const pairHeadset = httpsCallable(functions, "pairHeadset");
        const result = await pairHeadset({ pairingCode: code });

        const { customToken, message } = result.data;

        setSuccessMessage(message || "Pairing successful!");

        setTimeout(() => {
          handleClose();
        }, 2000);

        console.log("Custom Token:", customToken);
      } catch (ex) {
        console.error("Error pairing headset:", ex);
        setError(
          ex.message || "Please verify the pairing code and try again!"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Stack>
      <Button variant="contained" style={{ height: "30px" }} onClick={handleOpen}>
        Pair Headset
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ textAlign: "center" }}>
            Enter Headset Pairing Code
          </Typography>
          <Box display="flex" justifyContent="center" gap={1} mt={2} >
            {[...Array(6)].map((_, index) => (
              <TextField
                key={index}
                id={`input-${index}`}
                value={pairingCode[index] || ""}
                onChange={(e) => handleCodeChange(e, index)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handlePair();
                  }
                }}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center", fontSize: "1.5rem" },
                }}
                sx={{
                  width: "50px",
                }}
                variant="outlined"
                disabled={isLoading}
              />
            ))}
          </Box>
          {error && (
            <Typography variant="body2" color="error" mt={2}>
              {error}
            </Typography>
          )}

          <Box display="flex" justifyContent="center" alignItems="center" mt={4} gap={3}>
    
            <Button
              variant="contained"
              color="warning"
              onClick={handleClose}
              style={{ width: '150px' }}
            >
              Skip for now
            </Button>

            <Button
              variant="contained"
              color="success"
              onClick={handlePair}
              disabled={isLoading}
              style={{ width: '150px' }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Pair'}
            </Button>
          </Box>


          {successMessage && (
            <Typography variant="body2" color="success.main" mt={2}>
              {successMessage}
            </Typography>
          )}
        </Box>
      </Modal>
    </Stack>
  );
}
