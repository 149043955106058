import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------




const BrandPage = lazy(() => import('src/sections/brand/view/brand-view'));


export const brandRoutes = [
    {
        path: 'brand',
          element: (
              <AuthGuard>
                <DashboardLayout>
                  <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                  </Suspense>
                </DashboardLayout>
              </AuthGuard>
            ),
        children: [
          { path: ':id', element: <BrandPage /> },
        ],
      },
      
];
