import { lazy, Suspense } from 'react';


// ----------------------------------------------------------------------



const Ar = lazy(() => import('src/pages/ar/ar'));
const ShopifyAr = lazy(() => import('src/pages/ar/shopify-ar'));


export const arRoutes = [
    {
        path: 'ar',
        children: [
          { path: ':companyId/:productId/:productName', element: <Ar /> },
        ],
      },
      {
        path: 'shopify-ar',
        children: [
          { path: ':shopName/:productGlbUrl', element: <ShopifyAr /> },
        ],
      }
      
];
